import React, { useEffect } from 'react';

export interface IntercomProps {
  appId: string,
  agent: {
    name: string,
    email: string,
    id: number,
    userHash: string,
    createdAt?: number
  }
  backgroundColor?: string
  actionColor?: string,
  alignment?: string,
  horizontalPadding?: number,
  verticalPadding?: number,
  onHide?: () => void,
  onShow?: () => void,
  onUnreadCountChange?: (unreadCount: number) => void
}

export const IntercomFAB: React.FC<IntercomProps> = ({
  appId,
  agent,
  backgroundColor = null,
  actionColor = null,
  alignment = 'right',
  horizontalPadding = 20,
  verticalPadding = 20,
  onHide = null,
  onShow = null,
  onUnreadCountChange = null
}) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.innerHTML = `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/pxv75bqk';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};l()}})()`;
    document.head.appendChild(script);
  })

  useEffect(() => {
    const { Intercom } = (window as any);
    if (Intercom) {
      Intercom('boot', {
        app_id: appId,
        name: agent.name,
        email: agent.email,
        user_id: agent.id,
        user_hash: agent.userHash,
        created_at: agent.createdAt,
        alignment,
        background_color: backgroundColor,
        action_color: actionColor,
        horizontal_padding: horizontalPadding,
        vertical_padding: verticalPadding,
      });

      onHide && Intercom('onHide', onHide);
      onShow && Intercom('onShow', onShow);
      onUnreadCountChange && Intercom('onUnreadCountChange', onUnreadCountChange);
    }
  }, [(window as any).Intercom]);

  return <div></div>
}
